<template>
  <div>
    <div class="row">
      <div class="col-xl-12 text-center">
        <label style="position: relative">
          <div>
            <!-- <img src="media/images/adidas.svg" /> -->
            <!-- <img class="logo" :src="client_detail.logo" /> -->

            <img
              v-b-modal.edit-profile-image
              style="
                height: 85px;
                width: 80px;
                border-radius: 20px;
                border: 2px solid white;
                box-shadow: 0px 0px 5px gainsboro;
              "
              :src="previewImage"
            />
            <img
              v-b-modal.edit-profile-image
              class="iconImage"
              style="bottom: -5px; right: -7px"
              src="/media/svg/icons/General/edit.png"
            />
          </div>
        </label>
        <div>
          <h1 class="ml-4 main-header">{{ client_detail.name }}</h1>
        </div>
        <div><span>What would you like to see</span></div>
      </div>
      <div class="container text-center mb-0">
        <div
          class="
            d-flex
            flex-wrap
            justify-content-center
            text-center
            align-items-center
          "
        >
          <b-button
            v-if="clientType == 'P' || clientType == 'P&I'"
            class="selection col-md-2 col-8"
            v-bind:class="{ active: selected == 1 }"
            @click="selectType(1)"
            ><img
              src="media/images/performance.svg"
              alt="performance"
              class="w-40"
            /><span>Performance</span></b-button
          >

          <b-button
            v-if="clientType == 'I' || clientType == 'P&I'"
            class="selection col-md-2 col-8"
            v-bind:class="{ active: selected == 2 }"
            @click="selectType(2)"
            ><img
              src="media/images/influencer.svg"
              alt="Influencer"
              class="w-40"
            />
            <br /><span>Influencer Management</span></b-button
          >
          <!-- <div>
            <b-button
              @click="navigateOfferPage"
              style="width: 100%"
              variant="light"
              >Offers</b-button
            >
          </div> -->
        </div>
      </div>
    </div>

    <b-row class="d-flex justify-content-center text-center">
      <b-col
        class="col-12 col-md-3 selection-box"
        role="button"
        @click="selectType(3)"
      >
        <b-avatar src="media/images/offers.png" text="" class="selection-icon">
        </b-avatar>
        <br />
        <strong>Offers</strong>
      </b-col>

      <b-col
        class="col-12 col-md-3 selection-box"
        role="button"
        @click="selectType(4)"
      >
        <b-avatar src="media/images/coupons.png" text="" class="selection-icon">
        </b-avatar>
        <br />
        <strong>Coupons</strong>
      </b-col>

      <b-col
        class="col-12 col-md-3 selection-box"
        role="button"
        @click="selectType(5)"
      >
        <b-avatar src="media/images/payout.png" text="" class="selection-icon">
        </b-avatar>
        <br />
        <strong>Payout</strong>
      </b-col>

      <b-col
        class="col-12 col-md-3 selection-box"
        role="button"
        @click="selectType(6)"
      >
        <b-avatar
          src="media/images/cancellation.png"
          text=""
          class="selection-icon"
        >
        </b-avatar>
        <br />
        <strong>Cancellation</strong>
      </b-col>
    </b-row>
    <b-modal
      :no-close-on-backdrop="true"
      :title="'Update Profile Picture'"
      id="edit-profile-image"
      centered
      @ok="uploadImage"
      @cancel="uploadImage"
      ok-title="DELETE"
      cancel-title="CANCEL"
      hide-header-close
    >
      <center>
        <label style="position: relative" for="UserImage">
          <div>
            <img
              style="
                height: 85px;
                width: 80px;
                border-radius: 20px;
                border: 2px solid white;
                box-shadow: 0px 0px 5px gainsboro;
              "
              :src="previewImage"
            />
            <img
              style="right: 0px; bottom: 0px"
              class="iconImage"
              v-b-modal.edit-profile-image
              src="/media/svg/icons/General/camera icon-20220111-114818.png"
            />
          </div>
        </label>
      </center>
      <input
        style="display: none"
        type="file"
        @change="uploadImage"
        accept="image/jpeg/png"
        id="UserImage"
      />
      <template #modal-footer>
        <b-button
          @click="cancelSaveImage"
          :disable="isLoading"
          style="background-color: #ecf1f6"
        >
          CANCEL
        </b-button>
        <b-button
          style="background-color: #f30e53"
          variant="danger"
          :disable="isLoading"
          @click="remove"
        >
          REMOVE
        </b-button>
        <b-button
          @click="saveImage"
          :disable="isLoading"
          style="background-color: #0881f9; color: white"
        >
          SAVE <b-spinner small v-if="isLoading" />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import ApiService from "../../core/services/api.service";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import CellRendererAvatars from "@/view/pages/home/Planners/CellRendererAvatar.vue";
import CellRendererName from "@/view/pages/home/Planners/CellRendererName.vue";
import CellRendererMATERIALS from "@/view/pages/home/Planners/CellRendererMATERIALS.vue";
import CellRendererCoupon from "@/view/pages/home/Planners/CellRendererCoupon.vue";
import startplan from "@/view/pages/home/Planners/startplan.vue";
import { AgGridVue } from "ag-grid-vue";
import Button from "./vue-bootstrap/Button.vue";
import { mapGetters } from "vuex";
import ApiService from "../../core/services/api.service";
// import { mapActions } from 'vuex';

export default {
  components: {
    Dropdown7,
    AgGridVue,
    CellRendererAvatars,
    CellRendererName,
    CellRendererMATERIALS,
    CellRendererCoupon,
    startplan,
    Button,
  },

  data() {
    return {
      selected: 1,
      clientType: "",
      previewImage: "media/svg/avatars/default-avatar.svg",
      previewImageBackup: "",
      image: null,
      isLoading: false,
      is_image_delete: false,
    };
  },
  async mounted() {
    this.clientType = this.$route.params.type;
    // set select client detail in store
    await this.$store.dispatch("CLIENT_ACTION", this.$route.params.id);
    this.previewImage = this.client_detail.logo;
    this.previewImageBackup = this.client_detail.logo;
  },
  methods: {
    closethispopup() {
      this.$bvModal.hide("edit-profile-image");
    },
    selectType(type) {
      var id = this.$route.params.id;
      if (type == 1) {
        this.$router.push({ name: "performancehome", params: { id: id } });
      }
      if (type == 2) {
        this.$router.push({ name: "influencermanagement", params: { id: id } });
        // console.log("Management")
      }

      if (type == 3) {
        this.$router.push({ name: "offerhome", params: { id: id } });
      }

      if (type == 4) {
        this.$router.push({ name: "coupon-management", params: { id: id } });
      }

      if (type == 5) {
        this.$router.push({ name: "payout", params: { id: id } });
      }

      if (type == 6) {
        this.$router.push({ name: "cancellation-rule", params: { id: id } });
      }

      this.selected = type;
    },
    navigateOfferPage() {
      var id = this.$route.params.id;
      this.$router.push({ name: "offerhome", params: { id: id } });
    },
    async uploadImage(e) {
      console.log("HRRER", e);
      if (!e.target.files || !e.target.files.length) {
        // this.influencerData.image = null;
        this.previewImage = "media/svg/avatars/default-avatar.svg";
        // this.influencerData.is_image_delete = true;
        return;
      }

      // delete this.influencerData.is_image_delete;
      // this.influencerData.image = e.target.files[0];
      this.image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onload = async (e) => {
        this.previewImage = e.target.result;
      };
    },
    async remove() {
      let formData = new FormData();
      formData.append("is_image_delete", true);

      await ApiService.post(`clients/${this.$route.params.id}`, formData);
      this.previewImage = "media/svg/avatars/default-avatar.svg";
      this.previewImageBackup = this.previewImage;
      this.closethispopup();
    },

    cancelSaveImage() {
      this.previewImage = this.previewImageBackup;
      this.closethispopup();
    },
    async saveImage() {
      if (!this.image) {
        this.closethispopup();
        return;
      }
      this.isLoading = true;
      let formData = new FormData();
      formData.append("logo", this.image);
      formData.append("is_image_delete", false);

      await ApiService.post(`clients/${this.$route.params.id}`, formData);
      this.previewImageBackup = this.previewImage;
      this.isLoading = false;
      this.closethispopup();
    },
  },
  watch: {},
  computed: {
    ...mapGetters(["ClientGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
  },
};
</script>

<style lang="scss">
.w-40 {
  width: 40%;
}
.selection {
  border: none;
  background: transparent !important;
  height: auto;
  padding: 12px;
  margin: 20px 4px;
  font-weight: bold;
  border: 2px solid transparent !important;
  &.active {
    border: 2px solid #586dfa !important;
  }
  span {
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-top: 20px;
  }
}
.selection-box {
  border-radius: 10px;
  height: 130px !important;
  box-shadow: 2px 2px 11px 0 #ccc !important;
  max-width: 200px !important;
  margin: 10px !important;
  padding-top: 38px !important;
  .selection-icon {
    margin-bottom: 5px;
  }
}
.selection-box:hover {
  border: 2px solid #586dfa !important;
}
.iconImage {
  height: 25px;
  width: 25px;
  padding: 3px;
  border-radius: 50%;
  background-color: #f3f6f9;
  position: absolute;
  bottom: 17px;
  right: 165px;
}
</style>
